<template>
  <div @click.stop="closeEdit">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_3"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_3">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <form id="formItems" class="needs-validation" novalidate>
            <div class="modal-header justify-content-between">
              <h2 class="modal-title">แก้ไขรายการสั่งซื้อ</h2>
              <button
                data-bs-dismiss="modal"
                @click="closeEdit"
                type="button"
                class="btn btn-sm"
              >
                <i
                  class="bi bi-x m-0 p-0"
                  id="close-btn"
                  :style="styleClose"
                  @mouseenter="changeStyle"
                  @mouseleave="undoStyle"
                ></i>
              </button>
            </div>

            <div class="modal-body">
              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">เลขที่เอกสาร</label>
                <div class="col-sm-10">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">Item no.</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_code"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">Item name.</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_name"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">Barcode</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_barcode"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">จำนวนสั่งซื้อ(หน่วย)</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.item_amt"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10 required"
                  >จำนวนรับเข้าคลัง(หน่วย)</label
                >
                <div class="col-sm-10">
                  <input
                    v-model="form.item_amt_recieve"
                    @blur="onBlurInputReceive(form.item_amt_recieve)"
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                    :style="
                      isValid
                        ? ''
                        : form.item_amt_recieve
                        ? ''
                        : 'border-color:red'
                    "
                    required
                  />
                  <div
                    v-if="
                      isValid ? false : form.item_amt_recieve ? false : true
                    "
                    style="color: red"
                    class="text-end"
                  >
                    กรุณากรอก
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">ราคา / หน่วย(บาท)</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.product_cost_price"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">ส่วนลด / หน่วย(บาท)</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.item_discount"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>

              <div class="row d-flex justify-content-center mb-4">
                <label class="col-sm-10">VAT</label>
                <div class="col-sm-10">
                  <input
                    v-model="form.vat"
                    disabled
                    type="text"
                    class="form-control"
                    id="inputGroupFile01"
                  />
                </div>
              </div>
            </div>

            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer class="mt-2 mb-6 ms-6 row d-flex justify-content-center">
              <div
                class="card-toolbar ps-0 col-sm-10 d-flex justify-content-start"
              >
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                >
                  บันทึก
                </button>
                <button
                  @click="closeEdit"
                  type="button"
                  id="closeDialog"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import purchaseApi from "@/api/purchase/";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { set } from "object-path";

export default {
  props: {
    dialogEditPd: Boolean,
    dataEditItem: Object,
  },
  data: () => ({
    isSubmit: false,
    styleClose: "font-size: 25px",
    isValid: true,

    oldAmountRecieve: null,

    oldInputReceive: "",

    form: {
      vat: 0,
    },
  }),
  watch: {
    dialogEditPd(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        if (this.dataEditItem) {
          this.oldAmountRecieve = this.dataEditItem.item_amt_recieve;
          this.form = { ...this.form, ...this.dataEditItem };
          this.oldInputReceive = this.dataEditItem.item_amt_recieve;
          this.searchProduct = this.dataEditItem.product_barcode;
          this.calculateVat();
          console.log(this.oldInputReceive, 1234);
        }
      }
    },
  },
  methods: {
    async submit() {
      // await this.validateForm();
      // await this.checkFormIsEmpty();
      // this.isSubmit = true;
      // if (this.isValid) {
      //   document.getElementById("closeDialog").click();
      // }
      let updateResponse = [];
      delete this.form;

      if (this.form.item_amt !== this.form.item_amt_recieve) {
        this.form = {
          ...this.form,
          item_amt_old_recieve: parseFloat(this.oldInputReceive),
          item_amt_new_recieve:
            parseFloat(this.form.item_amt_recieve) -
            parseFloat(this.oldInputReceive),
        };
      } else {
        this.form = {
          ...this.form,
          id: this.form.item_id,
        };
      }

      try {
        updateResponse = await purchaseApi.purchaseOrder.updateItem(
          this.form.item_id,
          this.form
        );
      } catch (error) {
        console.log(error);
      }
      if (updateResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          timer: 2500,
          title: "สำเร็จ",
          title: "แก้ไขจำนวนรับเข้าคลังสำเร็จ",
          showConfirmButton: false,
        }).then((result) => {
          this.$emit("getOne");
          document.getElementById("closeDialog").click();
        });
      }
    },
    checkFormIsEmpty() {
      !this.form.item_amt_recieve
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    closeEdit() {
      this.$emit("closeDialogEditPd");
    },
    changeStyle() {
      this.styleClose =
        "font-size: 25px;background-color: LightGrey;border-radius: 25px;color: white;";
    },
    undoStyle() {
      this.styleClose = "font-size: 25px;";
    },
    calculateVat() {
      this.form.vat =
        ((this.form.product_cost_price - this.form.item_discount) *
          this.form.item_amt *
          7) /
        100;
    },
    onBlurInputReceive(input) {
      console.log(input);
    },
  },
};
</script>
